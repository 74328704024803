<template>
  <div
    v-if="isLoading"
    style="
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="text-center px-5 pb-7">
      <img
        alt="Logo"
        class="max-h-50px"
        src="/media/loading_icon.gif"
        style="width: 40px"
      />
    </div>
  </div>
  <div v-else class="card-body border-top p-9 ps-14">
    <div class="row mb-2">
      <div class="row mb-5">
        <!--begin::Label-->
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span> {{ $t("modals.pollster.pollTitle") }}</span>
        </label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-6 fv-row">
          <Field
            type="string"
            name="surveyTitle"
            v-model="poll.surveyTitle"
            class="form-control form-control-lg form-control-solid"
            :placeholder="$t('profile.enterName')"
            readonly
          />
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="surveyTitle" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="row mb-5">
        <!--begin::Label-->
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span> {{ $t("modals.pollster.pollDesc") }}</span>
        </label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-6 fv-row">
          <Field
            type="text"
            name="surveyDescription"
            class="form-control form-control-lg form-control-solid"
            :placeholder="$t('profile.enterSurname')"
            v-model="poll.surveyDescription"
            readonly
          />
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="surveyDescription" />
            </div>
          </div>
        </div>
        <!--end::Col-->
      </div>
    </div>
    <div class="row mb-2">
      <div class="row mb-5">
        <!--begin::Label-->
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span>{{ $t("modals.pollster.startDate") }}</span>
        </label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-6 fv-row">
          <Field
            type="string"
            name="name"
            class="form-control form-control-lg form-control-solid"
            :placeholder="$t('profile.enterName')"
                        :value="new Date(poll.startDate).toLocaleString('tr-TR', { dateStyle: 'short', timeStyle: 'full' })"
            readonly
          />

          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="name" />
            </div>
          </div>
        </div>
        <!--end::Col-->
      </div>
    </div>
    <div class="row mb-2">
      <div class="row mb-5">
        <!--begin::Label-->
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span>{{ $t("modals.pollster.endDate") }}</span>
        </label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-6 fv-row">
          <Field
            type="string"
            name="name"
            class="form-control form-control-lg form-control-solid"
            :value="new Date(poll.endDate).toLocaleString('tr-TR', { dateStyle: 'short', timeStyle: 'full' })"

            readonly
          />
          <!--            :placeholder="$t('profile.enterName')"-->

          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="name" />
            </div>
          </div>
        </div>
        <!--end::Col-->
      </div>
    </div>
    <div class="row mb-2">
      <div class="row mb-5">
        <!--begin::Label-->
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span>{{ $t("modals.pollster.duration") }}</span>
        </label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-6 fv-row">
          <Field
            type="string"
            name="name"
            class="form-control form-control-lg form-control-solid"
            v-model="poll.sessionDuration"
            value="02:00"
            readonly
          />

          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="name" />
            </div>
          </div>
        </div>
        <!--end::Col-->
      </div>
    </div>
    <div class="row mb-2">
      <div class="row mb-5">
        <!--begin::Label-->
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span>{{ $t("modals.pollster.status") }}</span>
        </label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-6 fv-row">
          <Field
            type="string"
            name="name"
            class="form-control form-control-lg form-control-solid"
            v-model="poll.status"
            readonly
          />

          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="name" />
            </div>
          </div>
        </div>
        <!--end::Col-->
      </div>
    </div>
    <div class="row mb-2">
      <div class="row mb-5">
        <!--begin::Label-->
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span>{{ $t("modals.pollster.percentComplete") }}</span>
        </label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-6 fv-row">
          <Field
            type="string"
            name="name"
            class="form-control form-control-lg form-control-solid"
            :value="poll.sessionCompletedPercentage + '%'"
            readonly
          />

          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="name" />
            </div>
          </div>
        </div>
        <!--end::Col-->
      </div>
    </div>
    <!-- <div class="row mb-2">
      <div class="row mb-5">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span>Duygu Puanı</span>
        </label>

        <div class="col-lg-6 fv-row">
          <Field
            type="string"
            name="name"
            class="form-control form-control-lg"
            :placeholder="$t('profile.enterName')"
            value="Positive: %100 Negative: %0"
          />

          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="name" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="row mb-5">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span>Duygu Analizi</span>
        </label>

        <div class="col-lg-6 fv-row">
          <Field
            type="string"
            name="name"
            class="form-control form-control-lg"
            :placeholder="$t('profile.enterName')"
            value="Angry: %0 Disgust: %0 Fear: %0 Happy: %0 Sad: %0 Surprise: %0 Neutral: %0"
          />

          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="name" />
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <!--end::Form-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute } from "vue-router";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { PollSessionDetailModel } from "@/domain/pollster/poll-statistic/poll-session-detail/model/PollSessionDetailModel";

export default defineComponent({
  name: "userPollDetail",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const isLoading = ref<boolean>(false);

    const pollId = ref<any>(Number(route.params["id"]));

    const store = useStore();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const swalNotification = new SwalNotification();

    const poll = ref<PollSessionDetailModel>({});

    const getPoll = async () => {
      isLoading.value = true;

      const pollSessionDetailModel: PollSessionDetailModel = {
        id: pollId.value,
      };

      pollsterController
        .pollSessionDetail(pollSessionDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(poll.value, response.getValue());
            if (poll.value.endDate) {
              poll.value.status = false; // status true ise açıktır. false ise kapanmıstır/bitmistir
            } else {
              poll.value.status = true;
            }
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
      getPoll();
    });

    return {
      poll,
      isLoading,
    };
  },
});
</script>
