
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute } from "vue-router";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { PollSessionDetailModel } from "@/domain/pollster/poll-statistic/poll-session-detail/model/PollSessionDetailModel";

export default defineComponent({
  name: "userPollDetail",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const isLoading = ref<boolean>(false);

    const pollId = ref<any>(Number(route.params["id"]));

    const store = useStore();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const swalNotification = new SwalNotification();

    const poll = ref<PollSessionDetailModel>({});

    const getPoll = async () => {
      isLoading.value = true;

      const pollSessionDetailModel: PollSessionDetailModel = {
        id: pollId.value,
      };

      pollsterController
        .pollSessionDetail(pollSessionDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(poll.value, response.getValue());
            if (poll.value.endDate) {
              poll.value.status = false; // status true ise açıktır. false ise kapanmıstır/bitmistir
            } else {
              poll.value.status = true;
            }
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
      getPoll();
    });

    return {
      poll,
      isLoading,
    };
  },
});
